import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
  pure: true,
  standalone: true,
})
export class JoinPipe implements PipeTransform {
  public transform(values: unknown[], join = ', '): string {
    if (!Array.isArray(values)) {
      return '';
    }
    return values
      .map((value) => {
        if (typeof value !== 'string') {
          return '';
        }
        return value.trim();
      })
      .filter((value) => !!value)
      .join(join);
  }
}
